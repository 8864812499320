import { useFormik } from "formik";
import styles from './SuggestModal.module.scss';
import * as Yup from 'yup';
import { useSelector } from "../../store/hooks";
import { getToken } from "../../store/loginSlice";
import { useMutation } from "@apollo/client";
import { SUGGESTION_SEND } from "../../api/public/suggestion";
import ErrorAlert from "../ErrorAlert/ErrorAlert";
import SuggestSelect from "./components/SuggestSelect";
import { FeedbackThanks } from "./components/FeedbackThanks";
import { ModalNew } from "../ModalNew/ModalNew";
import { TextAreaFieldNew } from "../inputs/TextFieldNew/TextAreaFieldNew";
import { FormikTextFieldNew } from "../inputs/FormikTextFieldNew/FormikTextFieldNew";
import { ButtonNew } from "../ButtonNew/ButtonNew";
import { FormFieldNew } from "../inputs/FormFieldNew/FormFieldNew";

type Props = {
  isModalOpen: boolean;
  setIsModalOpen: () => void;
}

const REQUIRED = 'Обязательное поле';

export type SuggestOption = {
  label: string;
  name: string;
  options: {value: string, extra?: string}[];
  schema: any;
  withInput?: boolean;
  type: 'radio' | 'checkbox';
}

const suggestionQuestions: SuggestOption[] = [
  {
    label: 'Насколько вероятно, что вы порекомендуете наш маркетплейс друзьям и знакомым?',
    name: 'recommend',
    options: [{value: 'Очень вероятно'}, {value: 'Вероятно'}, {value: 'Маловероятно'}, {value: 'Очень маловероятно'}],
    schema: Yup.object().shape({
      label: Yup.string(),
      value: Yup.array().of(
        Yup.object().shape({
          value: Yup.string().required(REQUIRED),
          extra: Yup.string()
        }),
      ),
    }).test('recommend', 'Обязательное поле', function(value) {
      return value.value?.length ? true : false;
    }),
    withInput: false,
    type: 'radio'
  }
];

export const SuggestModalNew = ({isModalOpen, setIsModalOpen}: Props) => {

  const isLoggedIn = useSelector(getToken);

  const questionsSchema = Object.fromEntries(suggestionQuestions.map((field) => [field.name, field.schema]));
  const questionsInitialValues = Object.fromEntries(suggestionQuestions.map((field)=>[field.name, {label: field.label, value: []}]));

  const validationSchema = Yup.object().shape({
    emailPhone: isLoggedIn ? Yup.string() : Yup.string().required(REQUIRED),
    suggestionText: Yup.string(),
    ...questionsSchema
  });
  
  const formik = useFormik({
    initialValues: {
      emailPhone: '',
      suggestionText: '',
      ...questionsInitialValues
    },
    validationSchema,
    onSubmit: (values, actions) => {
      const { emailPhone, suggestionText, ...rest } = values;

      sendSuggestion({
        variables: {
          suggestion: {
            text: suggestionText,
            emailPhone,
            options: Object.values(rest).filter((q: any) => q.value.length > 0) as [{label: string, value: SuggestOption['options'], other?: string}]
          }
        }
      });
      actions.resetForm();
    }
  });

  const [sendSuggestion, { error, called, reset }] = useMutation(SUGGESTION_SEND);

  return (
    <ModalNew title="Спасибо, что работаете с ENSYS!" show={isModalOpen} onClose={() => {setIsModalOpen(), reset()}}>
      {!called 
      ? <form onSubmit={formik.handleSubmit}>
          <div className={styles.container}>
            <ErrorAlert error={error} />
            <div className={styles.suggestContainer}>
              {!isLoggedIn && 
                <>
                  <div className={styles.emailPhone}>
                    <FormikTextFieldNew formik={formik} field="emailPhone" label="Укажите вашу почту или телефон для связи" required />
                  </div>
                </>
              }
              {suggestionQuestions.map((q) => (
                <FormFieldNew key={q.name} error={formik.errors[q.name as keyof typeof formik.errors]} label={q.label} required>
                  <SuggestSelect
                    field={q.name as keyof typeof formik.values}
                    formik={formik} 
                    type={q.type}
                    options={q.options}
                  />
                </FormFieldNew>
              ))}
              <div className={styles.suggestionText}>
                <FormFieldNew error={formik.errors.suggestionText} label='Место для вашего отзыва'>
                  <TextAreaFieldNew rows={6} onChange={formik.handleChange} value={formik.values.suggestionText} name='suggestionText' />
                </FormFieldNew>
              </div>
            </div>
            <ButtonNew variant='filled' onClick={formik.handleSubmit}>
              Отправить
            </ButtonNew>
          </div>
        </form>
      : 
        <div style={{display: 'flex', flexDirection: 'column', gap: 50, alignItems: 'center'}}>
          <FeedbackThanks />
          <ButtonNew variant='filled' onClick={() => {setIsModalOpen(), reset()}}>
            Закрыть
          </ButtonNew>
        </div>
      }
    </ModalNew>
  );
};
