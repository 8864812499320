import Head from 'next/head';
import styles from './Page.module.scss';
import Logo from '../../public/images/newSVG/logo.svg';
import Search from '../../public/images/newSVG/search.svg';
import Home from '../../public/images/newSVG/home.svg';
import CartOutline from '../../public/images/newSVG/cartOutline.svg';
import CabinetOutline from '../../public/images/newSVG/cabinetOutline.svg';
import Contacts from '../../public/images/newSVG/contacts.svg';
import Cabinet from '../../public/images/newSVG/cabinet.svg';
import Download from '../../public/images/newSVG/download.svg';
import Close from '../../public/images/newSVG/close.svg';
import Cart from '../../public/images/newSVG/cart.svg';
import { useEffect, useState } from 'react';
import clsx from 'clsx';
import { TextFieldNew } from '../inputs/TextFieldNew/TextFieldNew';
import { ButtonNew } from '../ButtonNew/ButtonNew';
import { FooterNew } from '../FooterNew/FooterNew';
import CitySelectNew from '../CitySelectNew/CitySelectNew';
import Link from 'next/link';
import { useRouter } from 'next/router';

type Props = {
  children: React.ReactNode;
  title?: string;
}

export const PageNew = (props: Props) => {
  const { title, children } = props;

  const router = useRouter();
  const { q } = router.query || '';

  const [isSearchExpanded, setIsSearchExpanded] = useState(false);
  const [search, setSearch] = useState('');

  useEffect(() => {
    setSearch(q as string);
  }, [q]);

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    if (search.trim()) {
      router.push(`/catalog/search?q=${encodeURIComponent(search)}`);
    }
  };
  
  return (
    <div className={styles.root}>
      <Head>
        <title itemProp="headline">{title}</title>
        <link rel="canonical" href="https://ensys.store" />
        <link rel="icon" href="/favicon.ico" />

        <meta property="og:url" content="https://ensys.store" />
        <meta property="og:type" content="website" />
        <meta property="og:title" content="Все инженерные системы" />
        <meta property="og:description" content="Закрывайте заявку на инженерное оборудование от многих поставщиков по лучшим ценам быстро и в одном окне" />
        <meta property="og:image" content="/opengraph.png" />

        <meta name="twitter:card" content="summary_large_image" />
        <meta property="twitter:domain" content="ensys.store" />
        <meta property="twitter:url" content="https://ensys.store" />
        <meta name="twitter:title" content="Все инженерные системы" />
        <meta name="twitter:description" content="Закрывайте заявку на инженерное оборудование от многих поставщиков по лучшим ценам быстро и в одном окне" />
        <meta name="twitter:image" content="/opengraph.png" />
      </Head>
      <div className={styles.container}>
        <header>
          <nav className={styles.mobileNav} aria-label="Мобильная навигация">
            <Link href='#'>
              <Home />
              Главная
            </Link>
            <Link href='#'>
              <Search />
              Поиск
            </Link>
            <Link href='#'>
              <CartOutline />
              Корзина
            </Link>
            <Link href='#'>
              <CabinetOutline />
              Войти
            </Link>
            <Link href='#'>
              <Contacts />
              Контакты
            </Link>
          </nav>
          <nav aria-label='Основная навигация' className={styles.nav1}>
            <Link href='/'>
              <Logo />
            </Link>
            <div className={styles.city}>
              <CitySelectNew />
            </div>
            <ul className={styles.pages}>
              <li>
                <Link href="/delivery">Доставка</Link>
              </li>
              <li>
                <Link href="/payment">Способ оплаты</Link>
              </li>
              <li>
                <Link href="/invest?utm_source=home">Инвесторам</Link>
              </li>
              <li>
                <Link href="/contacts">Контакты</Link>
              </li>
              <li style={{background: '#2376E514', padding: '2px 8px 2px 8px', borderRadius: '32px'}} className={styles.color_primary}>
                <Link href="https://partner.ensys.store/">Стать поставщиком</Link>
              </li>
            </ul>
          </nav>
          <hr className={styles.hr} />
          <nav aria-label='Дополнительная навигация' className={styles.nav2}>
            <ul className={styles.lvl1}>
              <li>
                <Link href='/'>
                  <Logo />
                </Link>
              </li>
              {/* <li>
                <button className={styles.outlinedBtn}>
                  <Catalog />
                  Каталог
                </button>
              </li> */}
              <li id={styles.desktopSearchContainer}>
                <form onSubmit={handleSubmit} className={styles.search}>
                  <button style={{display: 'none'}} type="submit" />
                  <TextFieldNew name='q' placeholder='Поиск' value={search} onChange={(e) => setSearch(e.target.value)} />
                </form>
              </li>
              <li className={isSearchExpanded ? clsx(styles.hideOnExpand, styles.headSpecBtn) : styles.headSpecBtn}>
                <ButtonNew type='link' href='/upload-specification' variant='secondary'>
                  <Download />
                  Создать заявку
                </ButtonNew>
              </li>
              <li className={isSearchExpanded ? styles.showOnExpand : ''} id={styles.adaptiveSearchContainer}>
                <button className={isSearchExpanded ? clsx(styles.searchIcon, styles.hideOnExpand) : clsx(styles.searchIcon)} onClick={() => setIsSearchExpanded(true)}>
                  <Search />
                  <p>Поиск</p>
                </button>
                <form onSubmit={handleSubmit} className={isSearchExpanded ? clsx(styles.searchExpanded, styles.search) : clsx(styles.searchCollapsed, styles.search)}>
                  <button style={{display: 'none'}} type="submit" />
                  <TextFieldNew placeholder='Поиск' value={search} onChange={(e) => setSearch(e.target.value)} />
                  <button onClick={() => setIsSearchExpanded(false)}>
                    <Close />
                  </button>
                </form>
              </li>
              <li>
                <Link className={styles.navBtn} href="#">
                  <Cabinet />
                  Войти
                </Link>
              </li>
              <li>
                <Link className={styles.navBtn} href="#">
                  <Cart />
                  Корзина
                </Link>
              </li>
            </ul>
            <ul className={styles.lvl2}>
              <li>
                <Link href='/catalog/otoplenie-i-vodosnabzhenie'>Отопление и водоснабжение</Link>
              </li>
              <li>
                <Link href='/catalog/santehnika'>Сантехника</Link>
              </li>
              <li>
                <Link href='/catalog/elektrosnabzhenie'>Электроснабжение</Link>
              </li>
              <li>
                <Link href='/catalog/nasosnoe-oborudovanie'>Насосное оборудование</Link>
              </li>
              <li>
                <Link href='/catalog/kanalizaciya'>Канализация</Link>
              </li>
              <li>
                <Link href='/catalog/ventilyaciya'>Вентиляция</Link>
              </li>
              <li>
                <Link href='/catalog/kondicionirovanie-i-holodosnabzhenie'>Кондиционирование и холодоснабжение</Link>
              </li>
              <li>
                <Link className={styles.color_primary} href='/catalog'>Все категории</Link>
              </li>
            </ul>
          </nav>
        </header>
      </div>
      {children}
      <FooterNew />
    </div>
  );
};