import Link from 'next/link';
import styles from './Breadcrums.module.scss';

type Props = {
  // children: never;
  items: Array<{
    text: string,
    url?: string,
  }>,
}

export const BreadcrumsNew = (props: Props) => {
  const { items } = props;
  
  return (
    <div className={styles.container}>
      {items.map((item, i) => (
        <div key={i}>
          {i > 0 ? <span className={styles.separator}>/</span> : null}
          {(item.url && i !== items.length - 1) ? (
            <Link href={item.url}>
              {item.text}
            </Link>
          ) : (
            <span className={styles.noLinkItem}>{item.text}</span>
          )}
        </div>
      ))}
    </div>
  );
};
