import { useState, useEffect } from 'react';
import clsx from 'clsx';
import { uploadFile } from '../../../api/public/file';
import styles from './FileUpload.module.scss';
import File from '../../../public/images/newSVG/file.svg';

type FileItemProp = {
  file: File;
  status: 'uploading' | 'uploaded' | 'error';
  error?: string;
  id?: string;
}

function FileItem({ file, onDelete }: { file: FileItemProp, onDelete: () => void }) {
  return (
    <div className={clsx(styles.fileItem, file.error && styles.fileItemError)}>
      <div className={styles.fileInfoContainer}>
        {file.status === 'uploading' && <span className={styles.spin} />}
        {file.file.name}
        {file.status !== "uploading" && <button onClick={onDelete}>удалить</button>}
      </div>
      {file.error ? (
        <div className={styles.error}>{file.error}</div>
      ) : null}
    </div>
  );
}

type Props = {
  onChange: (files: string) => void;
  setIsUploading?: (asd: boolean) => void;
  files?: FileList | null;
};

const MAX_SIZE = 50 * 1024 * 1024;

export function FileUpload(props: Props) {
  const { onChange, setIsUploading } = props;
  const [files, setFiles] = useState<Array<FileItemProp>>([]);


  const handleUploadFiles = (uploadedFiles: FileList | null | undefined) => {
    if (uploadedFiles === null || uploadedFiles === undefined) {
      return;
    }
    setFiles([
      ...files,
      ...Array.from(uploadedFiles).map((f) => ({
        status: f.size <= MAX_SIZE? 'uploading' : 'error',
        error: f.size > MAX_SIZE ? 'Размер файла не должен превышать 50мб' : undefined,
        file: f,
      }) as FileItemProp)
    ]);
  };

  useEffect(() => {
    handleUploadFiles(props.files);
  }, [props.files]);

  useEffect(() => {
    const uploadFiles = async () => {
      const uploads = files.map(async (f) => {
        if (f.status === 'uploading') {
          setIsUploading && setIsUploading(true);
          
          try {
            const response = await uploadFile(f.file);
            f.status = 'uploaded';
            f.id = response.data.filename;
          } catch (error: any) {
            f.status = 'error';
            f.error = error.message;
          }
          onChange(files
            .filter((file) => file.status === 'uploaded' && file.id)
            .map((file) => `${file.id},${file.file.name.replace(',', '').replace(';', '')}`)
            .join(';')
          );
        }
      });
  
      await Promise.all(uploads);
      setIsUploading && setIsUploading(false);
      setFiles([...files]);
    };
  
    if (files.filter((f) => f.status !== 'uploaded').length > 0) {
      uploadFiles();
    }
  }, [files]);

  const handleDelete = (idx: number) => () => {
    const a = [...files];
    a.splice(idx, 1);
    setFiles(a);
  };

  return (
    <div className={styles.filesDropContainer}>
      {files.length === 0 ? (
        <label className={styles.filesDrop}>
          <File />
          Прикрепите файл(ы)
          <input style={{display:'none'}} id='files' className={styles.input} type="file" onChange={(e) => handleUploadFiles(e.target.files)} />
        </label>
      ) : (
        <div className={styles.filesList}>
          {files.map((f, idx) => <FileItem file={f} key={idx} onDelete={handleDelete(idx)} />)}
        </div>
      )}
    </div>
  );
}
