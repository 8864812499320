import Link from 'next/link';
import { DEFAULT_CATEGORIES } from './categories';
import styles from './TopCategories.module.scss';


type Props = {
  categories?: typeof DEFAULT_CATEGORIES
}

export function TopCategoriesNew({ categories = DEFAULT_CATEGORIES }: Props) {

  return (
    <ul className={styles.catalog}>
      {categories.map((category, i) => (
        <li key={category.id}>
          <Link href={`/catalog/${category.slug}`}>
              <span
                className={styles.catalogItemIcon}
                style={{ backgroundImage: `url(/images/newSVG/categories/category${i + 1}.svg)` }}
              />
              <p>{category.title}</p>
          </Link>
        </li>
      ))}
    </ul>
  );
}
