import { ButtonHTMLAttributes } from "react";
import styles from "./Button.module.scss";
import Link from "next/link";

export type ButtonProps = {
  onClick?: () => void;
  loading?: boolean;
  disabled?: boolean;
  variant?: 'outlined' | 'filled' | 'secondary' | 'outlined-mini';
  icon?: React.ReactNode;
  type?: 'button' | 'link';
  href?: string;
  style?: ButtonHTMLAttributes<HTMLButtonElement>['style'];
  children: React.ReactNode;
}


function ButtonNew(props: ButtonProps) {
  const { onClick, loading, disabled, variant = 'outlined', children, type = 'button', style, href } = props;
  
  return type === 'button' ? (
    <button className={styles[variant]} style={style} onClick={onClick} disabled={loading || disabled}>
      {children}
    </button>
  ) : (
    <Link href={href || ''} className={styles[variant]} style={style}>
      {children}
    </Link>
  );
};

export { ButtonNew };